.TabVideoSection .btn{
 border: 1px solid rgba(34, 206, 186, 1);
 border-radius: 30px;
 font-size: 14px!important;
}
.TabVideoSection  .active-tab{
 box-shadow: -1px -1px 4px 0px rgba(18, 215, 191, 0.54);
 border: 1px solid rgba(34, 206, 186, 1);
 background: linear-gradient(94.51deg, #0E8295 0%, #22CEBA 96.57%)!important;
 color: #fff;
 border-radius: 30px;
 font-size: 14px!important;
}
/* Mobile View (Max Width 768px) */
@media (max-width: 768px) {
    .TabVideoSection .btn,
    .TabVideoSection .active-tab {
      font-size: 12px !important;
      padding: 6px 12px;
      border-radius: 20px;
    }
  }
.video-wrapper {
    position: relative;
    width: 100%;
    max-width: 880px; /* Maximum width on large screens */
    height: auto; /* Allow height to adjust */
    aspect-ratio: 16 / 9; 
    margin: auto;
}

.video-thumbnail,
.video-frame {
    width: 100%;
    max-width: 880px; /* Maximum width on large screens */
    height: 100%;
    border-radius: 18px;
    /* object-fit: cover; */
}

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width: 60px; /* Adjust for mobile */
    height: 60px;
}

@media (max-width: 768px) {
    .video-wrapper {
        max-width: 100%; /* Full width on smaller screens */
    }

    .play-button {
        width: 50px;
        height: 50px;
    }
}

@media (max-width: 480px) {
    .play-button {
        width: 40px;
        height: 40px;
    }
}


.helpdesk-video-icon__button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    padding: 20px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease;
}
.helpdesk-video-icon__button img {
  width: 80px; 
}
