.nextevent {
  position: relative;
  color: #000;
  height: 100vh;
  padding: 0px 0 10px 0px!important; 
  display: flex;
  align-items: center; 
  justify-content: center;
}

.nextevent::before {
  content: "";
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%;
  height: 100%; 
  background: url('https://ik.imagekit.io/cloy701fl/images/event-post-2.png?updatedAt=1743671349174') 
              no-repeat center top !important;
  background-size: contain; 
  background-position: center top; 
  opacity: 0.2; 
  z-index: -1;
}

.nextevent img{
  width: 65%;
 }

.small-text {
    font-size: 1.2rem; 
  }
  
  .highlight-text {
    color: #79F9EC;
    font-size: 50px;
  }
  .highlight-text-2 {
    color: #79F9EC; 
    font-size: 30px!important;
    font-weight: 600;
  }
  .bg-event{
    background: linear-gradient(to right, rgba(0, 151, 178, 0.1), rgba(126, 217, 87, 0.1));
}
  .bg-event h2{
   color: #008069; 
   font-weight: 600;
}
.small-icon {
  width: 40px;  /* अपनी जरूरत के हिसाब से सेट करें */
  height: 40px; /* aspect ratio maintain करने के लिए */
}
