/* Layout Styling */
.sticky-chat {
    position: sticky;
    top: 20px; /* Adjust to control the top offset */
    width: 300px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 15px;
    z-index: 100;
  }
  
  .sticky-chat .brand-header {
    display: flex;
    align-items: center;
  }
  
  .sticky-chat .brand-logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
  }
  
  .sticky-chat .brand-name {
    font-size: 16px;
    font-weight: bold;
  }
  
  .sticky-chat .brand-subtitle {
    font-size: 12px;
    color: gray;
  }
  
  .sticky-chat .chat-button {
    width: 100%;
    padding: 10px;
    background-color: #25d366;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .sticky-chat .chat-button:hover {
    background-color: #1da851;
  }
  @media (min-width: 992px) {
    .whatsapp-previews {
        position: -webkit-sticky; /* For Safari */
        position: sticky;
        top: 120px; /* Adjust based on where you want it to stick */
        /* height: 100vh;  */
    }
}

.whatsapp-previews .iphone-layout {
    background-size: cover;
    height: 380px;
    width: 100%;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 24px 50px 10px rgba(0, 102, 255, 0.07);
}
.cta-section{
    background-color: #fff;
    width: 100%;
    bottom: 0;
    position: absolute;
}
.cta-button{
    background: rgba(34, 206, 186, 1);
    border-radius: 30px;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 20px;
    width: 85%;
}

.widget-form label {
    display: block;
    text-align: left;
    font-size: 13px;
    color: rgba(78, 78, 80, 1);
    margin-bottom: 10px;
    font-weight: 500;
    text-transform: uppercase;
}/* Style all input elements (text, radio, etc.) inside .widget-form */
/* Style all input elements (text, radio, etc.) inside .widget-form */
.widget-form input {
    margin-top: 5px;
    padding: 8px;
    width: 100%;
    border: 1px solid #ccc;
    color: rgba(78, 78, 80, 1);
    border-radius: 5px;
    font-size: 12px;
}
.widget-form textarea {
    margin-top: 5px;
    padding: 8px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

/* Apply a focus effect to all input fields inside the form */
.widget-form input:focus {
    border-color: #54cdeb;
    outline: none;
}
.widget-form textarea:focus {
    border-color: #54cdeb;
    outline: none;
}

/* Optional: Style radio buttons inside the form */
.widget-form input[type="radio"] {
    width: auto;
    margin-right: 5px;
}

.widget-form .form-check-input{
    width: 15px;
}
.badge-btn{
  background: rgba(105, 221, 211, 0.74);
  color: rgba(76, 142, 205, 1);
  font-size: 12px;
  padding: 5px 12px;
  border: 1px solid rgba(76, 142, 205, 1);
  box-shadow: 0px 5px 15px 0px rgba(255, 255, 255, 0.15) inset;
  box-shadow: -1px -1px 4px 5px rgba(12, 222, 205, 0.11);
  border-radius: 22px;
  max-width: fit-content;
  word-wrap: normal;
  display: block;
  margin: 0 auto;
}

.heady-gradient {
  font-size: 1.6rem;
  font-weight: bold;
  background: linear-gradient(90.26deg, #133D63 39.03%, #18AF80 55.56%);
  background-clip: text; /* Standard Property */
  -webkit-background-clip: text; /* For WebKit Browsers */
  -webkit-text-fill-color: transparent; /* Makes text transparent */
  color: transparent; /* Fallback for unsupported browsers */
}

.chat-previews .welcome-message {
  position: relative;
  width: 100%;
  height: calc(100% - 100px) !important;
  margin-left: 1rem;
  padding: 10px;
  margin: 20px 30px 15px 20px;
}.chat-previews .welcome-message {
  padding: 0.8rem;
  font-size: 14px;
  align-items: start;
  max-width: 80% !important;
  line-height: 1.3125rem;
  letter-spacing: 0.0071rem;
  border-radius: 15px;
  text-align: left;
  background: rgba(255, 255, 255, 1);
  max-width: 10.1875rem;
  word-wrap: break-word;
}
.chat-previews .pre-filled-message {
  position: relative;
  width: 100%;
  height: calc(100% - 100px) !important;
  margin-left: 5rem;
  padding: 10px;
  background-color: #dcf8c6;
  padding: 0.8rem;
  font-size: 14px;
  align-items: start;
  max-width: 80% !important;
  line-height: 1.3125rem;
  letter-spacing: 0.0071rem;
  border-radius: 15px;
  text-align: left;
  word-wrap: break-word;
} .blurred-script {
  filter: blur(1.5px);
  user-select: none;
}