.qr-code {
  background: rgba(20, 77, 103, 1);
  box-shadow: -1px -1px 18px 1px rgba(11, 165, 181, 0.3);
  border-radius: 10px;
  padding: 15px 25px;
}
.qr-code-inner {
  background-color: #fff;
  padding: 20px;
  box-shadow: -1px -1px 18px 1px rgba(11, 165, 181, 0.3);
  border-radius: 10px;
}
.down-qr-code {
  background: rgba(223, 224, 226, 1);
  border-radius: 8px;
  min-height: 400px;
}
.qr-code label {
  font-size: 12px;
  color: rgba(34, 14, 39, 1);
  font-weight: 600;
  margin-bottom: 10px;
}
.qr-code .form-control {
  border-radius: 10px;
}
.qr-tabs .tabs button {
  padding: 8px 20px;
  background-color: transparent;
  margin: 0px 20px 0px 0px;
  font-size: 0.8rem;
  font-weight: 500;
  color: rgba(104, 103, 108, 1);
  border: 1px solid rgba(234, 234, 236, 1);
  border-radius: 8px;
  cursor: pointer;
}
.qr-tabs .tabs .active-tab {
  background: rgba(171, 223, 223, 1);
}
.qr-section.selected {
  background-color: rgb(243, 245, 254);
  color: rgb(29, 89, 249);
  padding: 10px;
  border-radius: 10px;
}

.label .tab-content {
  display: flex;
  justify-content: start;
  align-items: center;
  border: 1px solid rgba(153, 153, 153, 0.2666666667);
  border-radius: 10px;
  border: 1px solid rgba(153, 153, 153, 0.2666666667);
  height: 185px;
  font-size: 13px;
  padding: 10px 25px;
  background-color: #fff;
}
.label .qr-section {
  padding: 10px;
}
.label .qr-section:hover {
  background-color: rgb(243, 245, 254);
  color: rgb(29, 89, 249);
  border-radius: 10px;
}
.label .qr-row {
  display: flex;
  gap: 20px; /* Adjust space between images */
}
.label .qr-section {
  cursor: pointer;
}
.qr-info {
  padding: 10px 0px 0px;
}
.qr-value{
font-size: 12px;
}
.qr-percentage{
  font-size: 12px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #ccc;
  padding: 3px 9px;
  border-radius: 5px;
}
.logo-selection-container {
  display: flex;
  overflow-x: auto; /* ✅ Fix: Enable horizontal scrolling */
  gap: 10px;
  padding: 10px;
  white-space: nowrap;
   /* Hide default scrollbar in some browsers */
   scrollbar-width: thin; /* For Firefox */
   scrollbar-color: #888 #f0f0f0; /* Scroll thumb & track color */
}
/* Webkit Browsers (Chrome, Safari) */
.logo-selection-container::-webkit-scrollbar {
  height: 2px; /* Scrollbar height (thin) */
  border-radius: 10px; /* Scrollbar border radius */
}

.logo-preview {
  width: 50px;
  height: 50px;
  flex-shrink: 0; /* ✅ Fix: Prevent shrinking */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
 
}

.logo-img {
  max-width: 100%;
  max-height: 100%;
}

.border-style{
  border-radius: 8px;
  padding: 12px;
  border: 1px solid rgb(234, 234, 236);
}
.btn-grad{
 background: linear-gradient(94.23deg, #0f8597 -2.83%, #0a9e8d 98.65%);
border-radius: 15px;
color: #fff;
margin: 10px;
padding: 13px 40px;
}
