/* src/views/contact.css */
.contact-page {
  /* max-width: 600px; */
  margin: 0 auto;
  /* padding: 20px; */
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
}

/* button:hover {
  background-color: #0056b3;
} */

.googleMap {
  width: 100%;
}

.clients .client-card {
  padding: 22px 40px;
  border: 1px solid rgba(153, 153, 153, .2);
  border-radius: 15px;
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  text-align: left;
}

.clients .client-card img {
  width: 40%;
}

.clients .client-card h5 {
  padding: 5px 0px;  
  margin-top: 10px;
}

.contact  {
   background: -o-linear-gradient(45deg, transparent, #def1fa, transparent, #e8e8f3, transparent);
  background: linear-gradient(45deg, transparent, #def1fa, transparent, #e8e8f347, transparent);
  background-size: 400% 400%;
  -webkit-animation: 20s linear infinite gradient;
  animation: 20s linear infinite gradient;
  border-radius: 30px;
  overflow: hidden;
  padding-bottom: 20px;

}

/* new section */
.plat-new{
  background: linear-gradient(180deg, white, #fcf9f5 8%, #faf5f0 22%, #faf5f0 64%, #fcf8f4 92%, white);
  position: relative;
  overflow: hidden;

}
.new_button {
  background-color: #003d3d;
  background-image: linear-gradient(180deg, #007a7a, #003d3d);
  color: #cdface;
  text-align: center;
  transform-style: preserve-3d;
  border-style: none;
  border-radius: .6rem;
  flex-flow: row;
  flex: none;
  justify-content: center;
  align-items: center;
  margin-top: 1.2rem;
  margin-left: 0;
  margin-right: 0;
  padding: .8rem 2rem;
  font-size: .9rem;
  font-style: normal;
  font-weight: 500;
  transition: all .3scubic-bezier(.165,.84,.44,1), box-shadow .6s;
  display: flex;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  transform: perspective(1200px);
  box-shadow: inset 0 10px 16px -10px #ffffff0f, 0 8px 8px #001f1f0f, 0 4px 4px #001f1f0f, 0 2px 2px #001f1f0f, 0 0 1px #001f1f52, inset 0 -2px 1px #001f1f3d, inset 0 1px 1px #cdface14;
}
.Boxshadow{
  background-color: #fff;
  height: 70px;
  width: 70px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 auto;
  border-radius: 20px;

}
.box_icon{
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 auto;
  width: 50%;
}