/* Platform Capabilities Section */
.platform-capabilities-section {
  text-align: center;
  padding: 50px;
  background: #0f1d35; /* Dark blue background */
  color: white;
}

.platform-title {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.platform-description {
  font-size: 1rem;
  margin-bottom: 30px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Cards Container */
.platform-cards-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Row Styling */
.platform-row {
  display: grid;
  gap: 20px;
}

/* First Row - 70:30 */
.platform-first-row {
  grid-template-columns: 60% 40%;
}

/* Second Row - 30:70 */
.platform-second-row {
  grid-template-columns: 40% 60%;
}

/* Column Styling */
.platform-col-70, .platform-col-30 {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Card Styling */
.platform-card {
  width: 100%;
  height: 100%;
  border-radius: 18px;
  background: rgba(255, 255, 255, 0.1); /* Semi-transparent white */
  color: white;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.platform-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Specific Card Styles */
.platform-btn {
  padding: 5px 15px;
  font-size: 0.9rem;
  background: rgba(0, 255, 225, 1);
  border: 2px solid rgba(255, 255, 255, 1);
  color: rgba(48, 43, 43, 0.8);
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.platform-btn:hover {
  background: #00bfa5;
}

/* Responsive Design */
@media (max-width: 768px) {
  .platform-row {
    grid-template-columns: 1fr; /* Single column for mobile */
  }
  .pos-rel{
    position: relative!important;
  }
}

.link_circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center!important;
  justify-content: center;
  background: rgba(255, 255, 255, 0.04);
  padding: 10px;
  box-shadow: inset 0px 5px 15px rgba(255, 255, 255, 0.08);
  position: relative;
  margin-left: 12px;
  margin-top: 3px;
}

.link_circle::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: 50%;
  padding: 2px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.06) 100%);
  -webkit-mask: linear-gradient(white, white) content-box, linear-gradient(white, white);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

