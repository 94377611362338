.unique-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
.unique-popup h2 {
    text-align: start;
    font-size: 22px;
    margin-top: 0px;
 
  }
.unique-popup p {
    text-align: start;
    font-size: 35px;
    margin: 0px;
    line-height: 1.4;
    font-weight: 700;
    color: rgb(0, 0, 0);
  }
.unique-popup h6 {
    text-align: start;
    font-size: 20px;
  }
  
  .unique-popup__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
  
  .unique-popup__container {
    width: 80%;
    max-width: 800px;
    background: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 16px;
  }
  
  .unique-popup__close-icon {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    color: #000;
    height: 24px;
    cursor: pointer;
  }
  
  .unique-popup__content {
    /* flex: 1;
    padding: 20px; */
    text-align: left;
    /* flex: 1; */
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .unique-popup__image-container {
    flex: 1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .unique-popup__image {
    max-width: 100%;
    height: auto;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border: 2px solid transparent; /* Transparent border */
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  .unique-popup__button {
    margin-top: 20px;
    padding: 7px 20px;
    background-color: transparent;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    width: 150px;
  }
  
  .unique-popup__button:hover {
    background: rgba(255, 255, 255, 0.3);
    color: rgb(255, 255, 255);
    border-radius: 8px;
  }
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .animated-gradient {
    position: relative;
    padding: 20px 25px;
    z-index: 2;
    border-radius: 20px;
    background: linear-gradient(-45deg, #ee774e, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient-animation 15s ease infinite;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, 
                rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, 
                rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  }
 

 